import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import DashboardLoader from "./DashboardLoader";
import Modal from './Modal';

import { RestaurantContext } from "./RestaurantContext";

import apiService from "../services/apiService";

const UserFindInstagramForm = ({ handleSubmit }) => {
  const [slug, setSlug] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    let processedSlug = '';

    if (slug.includes('https://www.instagram.com/')) {
      processedSlug = slug.replace('https://www.instagram.com/', '');
      const processedSlugWithoutQuery = processedSlug.split('?')[0];

      handleSubmit(processedSlugWithoutQuery);
    } else if (slug.includes('https://instagram.com/')) {
      processedSlug = slug.replace('https://instagram.com/', '');

      const processedSlugWithoutQuery = processedSlug.split('?')[0];

      handleSubmit(processedSlugWithoutQuery);
    } else if (slug.includes('www.instagram.com/')) {
      processedSlug = slug.replace('www.instagram.com/', '');

      const processedSlugWithoutQuery = processedSlug.split('?')[0];

      handleSubmit(processedSlugWithoutQuery);
    } else if (slug.includes('instagram.com/')) {
      processedSlug = slug.replace('instagram.com/', '');

      const processedSlugWithoutQuery = processedSlug.split('?')[0];

      handleSubmit(processedSlugWithoutQuery);
    } else {
      toast.error('Please enter a valid Instagram URL.');
    }
  }

  return (
    <div className='flex flex-col items-center justify-center flex-grow min-w-full p-[16px] md:p-[50px] md:pl-[104px]'>
      <h1 className='text-center text-[32px] font-semibold text-white mb-[12px]'>Find Profile</h1>
      <p className='text-center text-[18px] font-medium text-white mb-[30px]'>Find your restaurant's profile to get started.</p>

      <form onSubmit={onSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
        <label className='flex flex-col gap-[16px] mb-[16px]'>
          <span className='text-white font-medium text-[17px]'>Your profile URL</span>

          <input
            type="text"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            placeholder="https://www.instagram.com/metro.taco"
            className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
          />
        </label>

        <button type="submit" disabled={!slug} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>Search</button>
      </form>
    </div>
  );
}

const InstagramProfileResult = ({ profile, onConfirmClicked, onCancelClicked }) => {
  return (
    <div className="flex flex-col items-center justify-center p-[32px] bg-[#1D2839] w-[500px] rounded-lg">
      <p className="text-white font-semibold text-xl mb-2">{profile.name}</p>

      <div className="flex gap-3 text-white font-medium mb-2">
        <span><span className="font-bold">{profile.posts}</span> Posts</span>
        <span><span className="font-bold">{profile.followed}</span> Followers</span>
        <span><span className="font-bold">{profile.follow}</span> Following</span>
      </div>

      <pre className="font-sans text-white mb-2 whitespace-normal max-w-full text-center">
        {profile.rawData.biography}
      </pre>

      <p className="text-white font-semibold mb-6">{profile.rawData.external_url}</p>

      <div className='grid grid-cols-2 gap-2 w-full'>
        <button
          className='flex items-center justify-center w-full h-[48px] bg-r-blue rounded-md text-white font-semibold'
          onClick={onConfirmClicked}
        >
          Confirm
        </button>

        <button
          className='flex items-center justify-center w-full h-[48px] bg-[#394558] rounded-md text-white font-semibold'
          onClick={onCancelClicked}
        >
          Search again
        </button>
      </div>
    </div>
  );
}

const FindInstagram = () => {
  const { restaurant, setRestaurant, isLoadingRestaurant, setIsProcessingInstagramResults, setHasFetchedSnapshots } = useContext(RestaurantContext);

  const [loadingMessage, setLoadingMessage] = useState(null);

  const [instagramProfile, setInstagramProfile] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (slug) => {
    setIsLoadingProfile(true);
    setLoadingMessage('Finding your profile...');

    const response = await apiService.findInstagramProfile({ slug });

    setInstagramProfile(response.data);
    setIsLoadingProfile(false);
    setLoadingMessage(null);
    setIsResultModalOpen(true);
  }

  const handleConfirmClicked = async () => {
    setIsLoadingProfile(true);
    setLoadingMessage('`Starting analysis...`');
    setIsResultModalOpen(false);

    const updateRestaurantResponse = await apiService.updateRestaurant({
      restaurantId: restaurant._id,
      updateData: {
        ...restaurant,
        instagram: {
          slug: instagramProfile.slug,
          name: instagramProfile.name,
          followers: instagramProfile.followed,
          following: instagramProfile.follow,
          posts: instagramProfile.posts
        }
      }
    });

    await apiService.startInstagramPostFetchingJob({
      restaurantId: restaurant._id,
      instagramSlug: instagramProfile.slug
    });

    setRestaurant(updateRestaurantResponse.data);
    setHasFetchedSnapshots(false);
    setIsProcessingInstagramResults(true);
    setIsLoadingProfile(false);
    setLoadingMessage(null);
    navigate('/dashboard');
  }

  const handleSearchAgainClicked = () => {
    setInstagramProfile(null);
    setIsResultModalOpen(false);
  }

  return (
    <>
      {isLoadingRestaurant || isLoadingProfile
        ? <DashboardLoader message={loadingMessage} />
        : <UserFindInstagramForm handleSubmit={handleSubmit} />
      }

      <Modal isOpen={isResultModalOpen}>
        <InstagramProfileResult
          profile={instagramProfile}
          onConfirmClicked={handleConfirmClicked}
          onCancelClicked={handleSearchAgainClicked}
        />
      </Modal>
    </>
  )
}

export default FindInstagram;
