import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { AuthContext } from "./AuthContext";

import apiService from "../services/apiService";

import Modal from "./Modal";
import DashboardLoader from "./DashboardLoader";
import ChangePlanPrompt from "./UserPlans/ChangePlanPrompt";
import Plans from "./UserPlans/Plans";

const UserPlans = () => {
  const { user, setUser } = useContext(AuthContext);

  const [isChangePlanPromptOpen, setIsChangePlanPromptOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [period, setPeriod] = useState('monthly');

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    setIsChangePlanPromptOpen(true);
  }

  const handlePlanChangeConfirmed = async () => {
    setIsChangePlanPromptOpen(false);
    await changePlan(selectedPlan);
  }

  const changePlan = async (plan) => {
    setLoading(true);
    setLoadingMessage('Updating your plan...');

    if (user.subscription.status === 'canceled') {
      const response = await apiService.updateCanceledSubscription({
        userId: user._id,
        priceId: period === 'monthly' ? plan.idMonthly : plan.idYearly
      });

      window.location.href = response.data.url;
    } else {
      await apiService.updateSubscription({
        userId: user._id,
        priceId: period === 'monthly' ? plan.idMonthly : plan.idYearly
      });

      const token = localStorage.getItem('token');
      const response = await apiService.fetchUserFromToken({ token });

      setUser(response.data);

      setLoading(false);
      setLoadingMessage(null);
      navigate('/profile');
    }
  }

  return (
    <>
      <Helmet>
        <title>Plans | Restaurant Report</title>
      </Helmet>

      {loading
        ? <DashboardLoader message={loadingMessage} />
        : <Plans
          period={period}
          setPeriod={setPeriod}
          handlePeriodSelected={setPeriod}
          onPlanSelected={handleSelectPlan}
        />
      }

      <Modal isOpen={isChangePlanPromptOpen}>
        <ChangePlanPrompt
          onConfirmClicked={handlePlanChangeConfirmed}
          onCancelClicked={e => setIsChangePlanPromptOpen(false)}
        />
      </Modal>
    </>
  )
}

export default UserPlans;
