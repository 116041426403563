import { ReactComponent as FlagIcon } from '../../assets/flag-icon.svg';
import { ReactComponent as UserPlanCheckmark } from '../../assets/user-plan-checkmark.svg';
import { ReactComponent as UserPlanFoodIcon } from '../../assets/user-plan-food-icon.svg';
import { ReactComponent as UserPlanPriceIcon } from '../../assets/user-plan-price-icon.svg';
import { ReactComponent as UserPlanServiceIcon } from '../../assets/user-plan-service-icon.svg';
import { ReactComponent as UserPlanDrinksIcon } from '../../assets/user-plan-drinks-icon.svg';
import { ReactComponent as UserPlanGoogleIcon } from '../../assets/user-plan-google-icon.svg';
import { ReactComponent as UserPlanGrubHubIcon } from '../../assets/user-plan-grubhub-icon.svg';
import { ReactComponent as UserPlanUberEatsIcon } from '../../assets/user-plan-ubereats-icon.svg';
import { ReactComponent as UserPlanDoorDashIcon } from '../../assets/user-plan-doordash-icon.svg';

import userPlans from "../../data/userPlans";

const NetworkIcon = ({ network }) => {
  switch (network) {
    case 'Google':
      return <UserPlanGoogleIcon />;
    case 'GrubHub':
      return <UserPlanGrubHubIcon />;
    case 'UberEats':
      return <UserPlanUberEatsIcon />;
    case 'DoorDash':
      return <UserPlanDoorDashIcon />;
    default:
      return null;
  }
}

const SegmentIcon = ({ segment }) => {
  switch (segment) {
    case 'Food':
      return <UserPlanFoodIcon />;
    case 'Price':
      return <UserPlanPriceIcon />;
    case 'Service':
      return <UserPlanServiceIcon />;
    case 'Drinks':
      return <UserPlanDrinksIcon />;
    default:
      return null;
  }
}

const Plans = ({ period, setPeriod, onPlanSelected }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center w-full pt-[40px]">
        <h1 className="text-white font-medium text-[32px] leading-[32px] mb-[40px]">Select Your Plan</h1>

        <div className="w-[300px] md:w-[370px] bg-[#1D2839] p-[16px] md:p-[10px] flex rounded-3xl">
          <p
            onClick={() => setPeriod('monthly')}
            className={`${period === 'monthly' ? 'bg-[#394558] text-white' : 'bg-transparent text-[#B9BECF]'} flex-grow rounded-2xl h-[36px] flex items-center justify-center text-white font-medium cursor-pointer transition-all`}
          >
            Monthly
          </p>

          <p
            onClick={() => setPeriod('yearly')}
            className={`${period === 'yearly' ? 'bg-[#394558] text-white' : 'bg-transparent text-[#B9BECF]'} flex-grow rounded-2xl h-[36px] flex items-center justify-center text-white font-medium cursor-pointer transition-all`}
          >
            Annually
          </p>
        </div>

        <div className="w-full flex items-center justify-center p-[16px] md:p-[40px]">
          <div className="flex flex-col md:grid md:grid-cols-3 gap-[32px]">
            {userPlans.map((plan) => (
              <div
                className={`${plan.isMostPopular ? 'border-r-blue' : 'border-[#394558]'} flex flex-col justify-between p-[40px] bg-[#1D2839] rounded-lg border w-[368px]`}
                key={`plan-${plan.name}`}
              >
                <div>
                  <div className="flex items-center justify-between mb-[16px]">
                    <h1 className="text-white font-medium text-[24px] leading-[24px]">{plan.name}</h1>

                    {plan.isMostPopular && (
                      <div className="flex items-center gap-[5px]">
                        <span className="text-[#728096] font-medium text-[14px] leading-[24px]">Most Popular</span>
                        <FlagIcon />
                      </div>
                    )}

                  </div>

                  <p className="text-r-blue font-semibold text-[46px] leading-[40px] mb-[14px]">
                    {period === 'monthly' ? `$${plan.priceMonthly}` : `$${plan.priceYearly}`}
                  </p>

                  <p className="text-[#728096] font-medium text-[16px] leading-[24px] mb-[10px]">
                    Discover customer insights across reviews form:
                  </p>

                  <ul className="flex flex-col gap-[10px] mb-[14px]">
                    {plan.availableNetworks.map((network, index) => (
                      <li
                        className="flex gap-[5px] items-center text-white font-medium text-[16px] leading-[24px]"
                        key={`plan-${plan.name}-network-${index}`}
                      >
                        <NetworkIcon network={network} /> {network}
                      </li>
                    ))}
                  </ul>

                  <p className="text-[#728096] font-medium text-[16px] leading-[24px] mb-[10px]">
                    Segment Reviews by:
                  </p>

                  <ul className="flex flex-col gap-[10px] mb-[14px]">
                    {plan.reviewSegmentation.map((segment, index) => (
                      <li
                        className="flex items-center gap-[5px] text-white font-medium text-[16px] leading-[24px]"
                        key={`plan-${plan.name}-segment-${index}`}
                      >
                        <SegmentIcon segment={segment} /> {segment}
                      </li>
                    ))}
                  </ul>

                  <p className="text-[#728096] font-medium text-[16px] leading-[24px] mb-[10px]">
                    Additions:
                  </p>

                  <ul className="flex flex-col gap-[10px] mb-[32px]">
                    {plan.additions.map((addition, index) => (
                      <li
                        className="text-white font-medium text-[16px] leading-[24px] flex items-center gap-[5px]"
                        key={`plan-${plan.name}-addition-${index}`}
                      >
                        <UserPlanCheckmark /> {addition}
                      </li>
                    ))}
                  </ul>
                </div>

                <button
                  onClick={() => onPlanSelected(plan)}
                  className="h-[56px] w-full bg-r-blue text-white text-[15px] leading-[16px] font-semibold rounded-lg"
                >
                  Select Plan
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Plans;
