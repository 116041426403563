import { useState } from "react";

import apiService from "../services/apiService";

const SpyOnRestaurantForm = ({ user, onSubmit }) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(name, address);
  }

  const handleBuyClicked = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const response = await apiService.generateSpyCheckoutSession({ userId: user._id });

    window.location.href = response.data.url;

  }

  return (
    <form onSubmit={handleSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
      <label className='flex flex-col gap-[16px] mb-[24px]'>
        <span className='text-white font-medium text-[17px]'>Restaurant Name</span>

        <input
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Restaurant Name"
          className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
        />
      </label>

      <label className='flex flex-col gap-[16px] mb-[16px]'>
        <span className='text-white font-medium text-[17px]'>Restaurant Address</span>

        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Restaurant Address"
          className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
        />
      </label>

      <button type="submit" disabled={!name || !address || user?.spyCredits === 0} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>
        Search ({`${user?.spyCredits} ${user?.spyCredits > 1 || user?.spyCredits === 0 ? 'reports' : 'report'} remaining`})
      </button>

      <button onClick={handleBuyClicked} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px]'>
        Buy Spy Report ({`$99`})
      </button>
    </form>
  )
}

export default SpyOnRestaurantForm;
