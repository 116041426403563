import Loader from "../Loader";

const ReviewSummaryLoader = () => {
  return (
    <div className="p-[16px] md:p-[32px] bg-[#2a323f] flex flex-col items-center justify-center">
      <p className="text-white text-center mb-1">We're getting your data ready, this might take a couple of minutes.</p>
      <p className="text-white text-center mb-6 text-sm">You'll be notified via e-mail as soon as the processing is finished.</p>

      <Loader />
    </div>
  )
}

export default ReviewSummaryLoader;
