const GoogleRestaurantResult = ({ restaurant, onConfirmClicked, onCancelClicked }) => {
  return (
    <div className="flex flex-col items-center justify-center p-[32px] bg-[#1D2839] w-[600px] rounded-lg">
      <img
        src={restaurant.profileImage}
        alt="business profile"
        className="w-[300px] rounded-lg mb-3"
      />

      <h1 className="text-xl font-semibold text-white mb-2">{restaurant.name}</h1>
      <p className="text-white font-medium text-center mb-2">{restaurant.formattedAddress}</p>
      <p className="mb-6 text-center font-medium text-white">Rated avg of {restaurant.globalRating} out of {restaurant.reviewCount.native.total} ratings on Google.</p>

      <div className='grid grid-cols-2 gap-2 w-full'>
        <button
          className='flex items-center justify-center w-full h-[48px] bg-r-blue rounded-md text-white font-semibold'
          onClick={onConfirmClicked}
        >
          Confirm
        </button>

        <button
          className='flex items-center justify-center w-full h-[48px] bg-[#394558] rounded-md text-white font-semibold'
          onClick={onCancelClicked}
        >
          Search again
        </button>
      </div>
    </div>
  )
};

export default GoogleRestaurantResult;
