import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "./AuthContext";

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';
import { ReactComponent as SearchIcon } from '../assets/search-icon.svg';
import { ReactComponent as InsightsIcon } from '../assets/insights-icon.svg';
import { ReactComponent as InfoIcon } from '../assets/info-icon.svg';
import { ReactComponent as UserIcon } from '../assets/user-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/close-icon.svg';
import { ReactComponent as LightbulbIcon } from '../assets/lightbulb-icon.svg';

const MobileMenuOverlay = ({ isOpen, onCloseClicked, onPrivacyPolicyClicked, onTermsOfServiceClicked }) => {
  const [isLegalSubmenuOpen, setIsLegalSubmenuOpen] = useState(false);

  const { isProUser, setIsUpgradeToProOpen } = useContext(AuthContext);

  const navigate = useNavigate();

  const handlePremiumLinkClicked = (e, path) => {
    e.preventDefault();

    if (isProUser) {
      navigate(path);
    } else {
      setIsUpgradeToProOpen(true);
    }
  }

  const handleCloseClicked = () => {
    setIsLegalSubmenuOpen(false);
    onCloseClicked();
  }

  return (
    <div className={`${isOpen ? 'fixed' : 'hidden'} top-0 right-0 bottom-0 left-0 flex flex-col min-h-screen z-30`}>
      <div className="flex md:hidden justify-between items-center p-[16px] bg-[#111822]">
        <Link to="/dashboard" className='md:hidden flex flex-col items-center font-semibold text-[16px] tracking-tight leading-none'>
          <span className='text-white'>Restaurant</span>
          <span className='text-r-blue'>Report</span>
        </Link>

        <CloseIcon
          onClick={handleCloseClicked}
          className="stroke-[#9CABC4] hover:stroke-[#18A6C5]"
        />
      </div>

      <div className="flex flex-col justify-between flex-grow py-[50px] bg-[#111822]">
        <div className="flex flex-col gap-[50px]">
          <NavLink to="/dashboard" className={({ isActive }) => `${isActive ? 'stroke-[#18A6C5]' : 'stroke-[#556277] '} flex gap-3 items-center hover:stroke-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}>
            <HomeIcon />
            <span className="font-figtree text-[21px] leading-[16px] text-white font-semibold">Dashboard</span>
          </NavLink>

          <NavLink to="/search" className={({ isActive }) => `${isActive ? 'stroke-[#18A6C5]' : 'stroke-[#556277] '} flex gap-3 items-center hover:stroke-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}>
            <SearchIcon />
            <span className="font-figtree text-[21px] leading-[16px] text-white font-semibold">Search</span>
          </NavLink>

          <NavLink
            to="/insights"
            onClick={e => handlePremiumLinkClicked(e, '/insights')}
            className={({ isActive }) => `${isActive ? 'fill-[#18A6C5]' : 'fill-[#556277] '} flex gap-3 items-center hover:fill-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}>
            <InsightsIcon />
            <span className="font-figtree text-[21px] leading-[16px] text-white font-semibold">Insights</span>
          </NavLink>

          <NavLink
            to="/opportunities"
            onClick={e => handlePremiumLinkClicked(e, '/opportunities')}
            className={({ isActive }) => `${isActive ? 'stroke-[#18A6C5]' : 'stroke-[#556277] '} flex gap-3 items-center hover:stroke-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}>
            <LightbulbIcon className={`w-[24px]`} />
            <span className="font-figtree text-[21px] leading-[16px] text-white font-semibold">Opportunities</span>
          </NavLink>
        </div>

        <div className="flex row gap-[20px] items-center px-4">
          <Link to="/profile" className='p-[18px] bg-r-gray-medium rounded-md'>
            <UserIcon />
          </Link>

          <div className="relative">
            <InfoIcon
              onClick={e => setIsLegalSubmenuOpen(!isLegalSubmenuOpen)}
              className={`${isLegalSubmenuOpen ? 'stroke-[#18A6C5]' : 'stroke-[#556277]'} cursor-pointer hover:stroke-[#18A6C5]`}
            />

            <div className={`${isLegalSubmenuOpen ? 'flex' : 'hidden'} flex-col absolute left-0 -top-24 bg-[#202731] rounded-md gap-[16px] w-[152px] items-start pt-[12px] pb-[12px] pl-[12px] text-white text-left text-[14px] font-medium`}>
              <button onClick={onPrivacyPolicyClicked} className="hover:text-r-blue">Privacy Policy</button>
              <button onClick={onTermsOfServiceClicked} className="hover:text-r-blue">Terms & Conditions</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenuOverlay;
