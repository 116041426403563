const Pagination = ({ page = 1, pages = 5, onClick }) => {
  return (
    <div className="flex text-white font-medium gap-2">
      <button
        className="bg-[#394558] p-1 px-2 py-1 rounded-md disabled:bg-[#1D2839] disabled:cursor-not-allowed"
        disabled={page === 1}
        onClick={() => onClick(page - 1)}
      >
        Previous
      </button>

      {Array.from({ length: pages }, (_, i) => (
        <button
          className={`${page === i + 1 ? 'bg-r-blue' : 'bg-[#394558]'}  px-2 py-1 rounded-md disabled:cursor-not-allowed`}
          disabled={page === i + 1}
          onClick={() => onClick(i + 1)}
        >
          {i + 1}
        </button>
      ))}

      <button
        className="bg-[#394558] px-2 py-1 rounded-md disabled:bg-[#1D2839] disabled:cursor-not-allowed"
        disabled={page === pages}
        onClick={() => onClick(page + 1)}
      >
        Next
      </button>
    </div>
  )
}

export default Pagination;
