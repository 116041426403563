import Loader from '../Loader';

const LoadingMessage = () => {
  return (
    <div className="flex gap-3 mb-[48px]">
      <Loader isSmall={true} />

      <div className='flex gap-3 items-center'>
        <p className='text-white font-figtree text-[16px] font-semibold'>Generating your response...</p>
      </div>
    </div>
  )
}

export default LoadingMessage;
