const userPlans = [
  {
    name: 'Basic',
    priceMonthly: 29,
    priceYearly: 259,
    idMonthly: 'price_1QLUNmAIeqCVMOVBi1puZ7Qq',
    idYearly: 'price_1QLUOhAIeqCVMOVB5eMifd4P',
    isMostPopular: false,
    availableNetworks: [
      'Google',
      'GrubHub',
      'DoorDash',
      'UberEats',
      'Yelp',
      'TripAdvisor',
    ],
    reviewSegmentation: [
      'Food',
      'Drinks',
      'Service',
      'Price'
    ],
    additions: [
      'Advanced Search UI',
      'Keyword Search',
      'Browse All Reviews',
    ]
  },
  {
    name: 'Pro',
    priceMonthly: 39,
    priceYearly: 349,
    idMonthly: 'price_1QLUP8AIeqCVMOVBf0NOyTe6',
    idYearly: 'price_1QLUPZAIeqCVMOVBxyLAXrLZ',
    isMostPopular: true,
    availableNetworks: [
      'Google',
      'GrubHub',
      'DoorDash',
      'UberEats',
      'Yelp',
      'TripAdvisor',
      'Instagram'
    ],
    reviewSegmentation: [
      'Food',
      'Drinks',
      'Service',
      'Price',
      'Requests / Advice',
      'Opportunities'
    ],
    additions: [
      'Advanced Search UI',
      'Keyword Search',
      'Browse All Reviews',
      'Find Opportunities with AI',
      'Q/A with AI',
      'Download Results as PDF'
    ]
  },
  {
    name: 'One Time Report',
    priceMonthly: 49,
    priceYearly: 49,
    isMostPopular: false,
    availableNetworks: [
      'Google',
      'GrubHub',
      'DoorDash',
      'UberEats',
      'Yelp',
      'TripAdvisor',
      'Instagram'
    ],
    reviewSegmentation: [
      'Food',
      'Drinks',
      'Service',
      'Price',
      'Requests / Advice',
      'Opportunities'
    ],
    additions: [
      'Find Opportunities with AI',
      'Download Results as PDF'
    ]
  }
];

export default userPlans;
