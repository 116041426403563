import { useContext, useState } from 'react';
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";

import { AuthContext } from './AuthContext';
import { RestaurantContext } from './RestaurantContext';

import apiService from '../services/apiService';

import DashboardLoader from "./DashboardLoader";
import Modal from './Modal';
import InitialQuestions from "./Insights/InitialQuestions";
import Chat from "./Insights/Chat";
import RelatedReviewsList from './RelatedReviewsList';

import { ReactComponent as SendIcon } from '../assets/send-icon.svg';
import RestaurantIndicator from './RestaurantIndicator';

const UserInsights = ({ restaurant }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const [selectedReviews, setSelectedReviews] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewReviewsClicked = (reviews) => {
    setSelectedReviews(reviews);
    setIsModalOpen(true);
  };

  const handleInitialMessageClicked = async (question) => {
    const newMessages = [...messages, { type: 'user', text: question }, { type: 'loading' }]
    setMessages(newMessages);
    setMessage('');

    await generateResponse(question, newMessages);
  };

  const handleMessageSent = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const newMessages = [...messages, { type: 'user', text: message }, { type: 'loading' }]
    setMessages(newMessages);
    setMessage('');

    await generateResponse(message, newMessages);
  };

  const generateResponse = async (message, previousMessages) => {
    try {
      const response = await apiService.generateInsightsResponse({
        restaurantId: restaurant._id,
        question: message
      });

      previousMessages.pop()
      setMessages([...previousMessages, { type: 'system', text: response.data?.answer, reviews: response.data?.results }]);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  return (
    <div className="flex flex-col items-center justify-center flex-grow relative pb-[80px]">
      {messages?.length === 0
        ? <InitialQuestions onMessageClicked={handleInitialMessageClicked} />
        : <Chat messages={messages} onViewReviewsClicked={handleViewReviewsClicked} />
      }

      <form
        onSubmit={handleMessageSent}
        className="fixed bottom-[40px] w-full md:w-auto justify-self-end mb-[36px] px-[16px] md:p-0"
      >
        <div className='relative'>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full md:w-[688px] text-white bg-[#1D2839] border-0 h-[72px] rounded-lg p-[25px] font-outfit text-[16px] leaading-[24px] font-medium placeholder:font-medium placeholder:text-[16px] placeholder:font-outfit placeholder:leading-[24px]"
            placeholder="Type your question"
          />

          <button type='submit' className='absolute top-[50%] -translate-y-[50%] right-[25px]'>
            <SendIcon />
          </button>
        </div>
      </form>

      <Modal isOpen={isModalOpen}>
        <RelatedReviewsList
          reviews={selectedReviews}
          onCloseClicked={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  )
};

const Insights = () => {
  const { user, isSubscriptionActive, isProUser } = useContext(AuthContext);
  const { restaurant, isLoadingRestaurant, hasFetchedRestaurant } = useContext(RestaurantContext);

  if (!restaurant && hasFetchedRestaurant) {
    return <Navigate to="/find-restaurant" replace />;
  }

  if (!isSubscriptionActive) {
    return <Navigate to="/profile" replace />;
  }

  if (user && !isProUser) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Insights | Restaurant Report</title>
      </Helmet>

      {isLoadingRestaurant || !user
        ? <DashboardLoader />
        : <UserInsights restaurant={restaurant} />
      }

      <RestaurantIndicator />
    </>
  )
}

export default Insights;
