import { Link } from "react-router-dom";

import { ReactComponent as BurgerMenuIcon } from '../assets/burger-menu.svg';

const MobileNavbar = ({ onBurgerMenuClicked }) => {
  return (
    <div className="flex md:hidden justify-between items-center p-[16px] bg-[#111822]">
      <Link to="/dashboard" className='md:hidden flex flex-col items-center font-semibold text-[16px] tracking-tight leading-none'>
        <span className='text-white'>Restaurant</span>
        <span className='text-r-blue'>Report</span>
      </Link>

      <BurgerMenuIcon onClick={onBurgerMenuClicked} />
    </div>
  )
}

export default MobileNavbar;
