import { useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from 'react-hot-toast';

import apiService from "../services/apiService";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await apiService.createForgotPasswordRequest({
        userEmail: email
      });

      toast.success('Password reset link sent to your email');
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <div className='flex flex-col items-center justify-between flex-grow min-h-screen p-[16px]'>
      <Helmet>
        <title>Forgot Password | Restaurant Report</title>
      </Helmet>

      <h1 className='text-[25px] font-semibold gap-1 flex items-center justify-center min-h-[80px] md:min-h-[150px]'>
        <span className='text-white'>Restaurant</span>
        <span className='text-r-blue'>Report</span>
      </h1>

      <div className='flex flex-col items-center justify-center flex-grow min-w-full'>
        <h2 className='text-center text-[32px] font-medium text-white mb-[24px]'>Forgot Password</h2>

        <form onSubmit={handleSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
          <label className='flex flex-col gap-[16px] mb-[24px]'>
            <span className='text-white font-medium text-[17px]'>Your E-mail</span>

            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
            />
          </label>

          <button type="submit" disabled={!email} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>Reset Password</button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
