import { createPortal } from 'react-dom';

const Modal = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return createPortal(
    <div className='fixed inset-0 backdrop-blur-lg flex items-center justify-center p-4 z-40'>
      {children}
    </div>,
    document.getElementById('portal')
  )
}

export default Modal;
