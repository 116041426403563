import { useNavigate } from 'react-router-dom';
import Select, { components, } from 'react-select';

import SampleThumbnail from '../../assets/sample-thumbnail.png';

import { ReactComponent as DocumentIcon } from '../../assets/document-icon.svg';
import { ReactComponent as FoodIcon } from '../../assets/food-icon.svg';
import { ReactComponent as DrinkIcon } from '../../assets/drink-icon.svg';
import { ReactComponent as ServiceIcon } from '../../assets/service-icon.svg';
import { ReactComponent as PriceIcon } from '../../assets/price-icon.svg';
import { ReactComponent as LightBulbIcon } from '../../assets/lightbulb-icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/home-icon.svg';
import { ReactComponent as InsightsIcon } from '../../assets/insights-icon.svg';

import apiService from '../../services/apiService';
import Loader from '../Loader';
import RestaurantSelect from './RestaurantSelect';

const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div className='flex items-center justify-between w-full'>
        <div className='flex items-center gap-2 flex-grow'>
          {props.data.snapshotDate
            ? <InsightsIcon className="stroke-white h-4" />
            : <HomeIcon className="stroke-white h-4" />
          }

          {props.data.label}
        </div>

        {props.data.snapshotDate && (
          <p className='font-semibold text-r-blue bg-r-gray-light py-1 px-2 text-sm rounded-md'>
            {props.data.snapshotDate}
          </p>
        )}
      </div>
    </components.SingleValue>
  )
}

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-4">
        {props.data.snapshotDate || props.data.isCreatingSnapshot
          ? <InsightsIcon className="stroke-white h-4" />
          : <HomeIcon className="stroke-white h-4" />
        }

        <div className='flex items-center justify-between w-full'>
          <p className="font-semibold text-white">{props.data.label}</p>

          {props.data.isCreatingSnapshot && <Loader isExtraSmall={true} />}

          {props.data.snapshotDate && (
            <p className='font-semibold text-r-blue bg-r-gray-light py-1 px-2 text-sm rounded-md'>{props.data.snapshotDate}</p>
          )}
        </div>
      </div>
    </components.Option>
  );
}

const CustomSelect = (props) => {
  return <Select
    {...props}
    components={{ SingleValue: CustomSingleValue, Option: CustomOption }}
    styles={{
      container: (styles) => ({
        ...styles,
        width: '100%'
      }),
      control: (styles) => ({
        ...styles,
        backgroundColor: '#17202e',
        borderColor: '#18A6C5',
        width: '100%',
        marginBottom: '0',
        minHeight: '56px'
      }),
      input: (styles) => ({
        ...styles,
        outline: 'none',
        border: 'none',
        color: 'white',
        boxShadow: 'none'
      }),
      singleValue: (styles) => ({
        ...styles,
        color: '#fff',
        fontWeight: '600',
        fontSize: '16px',
        padding: '10px 5px'
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        fill: '#18A6C5'
      }),
      menuList: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0
      }),
      menuPortal: (styles) => ({
        ...styles,
        border: '1px solid #18A6C5',
      }),
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? '#18A6C5' : isFocused ? '#2C3E50' : '#17202e',
        color: 'white',
        fontWeight: '600'
      })
    }} />
}

const RestaurantInfo = ({ currentAnalysis, onAnalysisClicked, restaurant, setRestaurant, setSelectedSnapshot, setHasFetchedSnapshots, resetLoadedRestaurant, user, selectOptions, onOpportunitiesClicked, isProUser }) => {
  const navigate = useNavigate();

  const handleAddNewClicked = async () => {
    navigate('/spy');
  }

  const handleRestaurantSelected = (snapshot) => {
    resetLoadedRestaurant();
    setRestaurant(snapshot.restaurant);
    setHasFetchedSnapshots(false);
    setSelectedSnapshot(snapshot);
  }

  return (
    <div className="p-[16px] md:p-[32px] bg-r-gray-medium flex flex-col">
      <img src={SampleThumbnail} alt="restaurant thumbnail" className="mb-[16px] md:mb-[24px]" />

      <div className='flex gap-4 mb-3'>
        <RestaurantSelect
          options={selectOptions}
          placeholder="Select a restaurant"
          spyPrice="$99"
          value={selectOptions.find(option => option.value === restaurant?._id)}
          spyCredits={user.spyCredits}
          onChange={e => handleRestaurantSelected(e)}
        />

        <button onClick={handleAddNewClicked} className='px-2 text-white bg-r-blue font-bold min-w-fit rounded-md text-sm'>
          Spy on a<br /> Restaurant
        </button>
      </div>

      <p className="font-medium text-[14px] md:text-[18px] text-[#9CABC4] mb-[24px] leading-[24px]">
        {restaurant?.google?.address || restaurant?.doordash?.address}
      </p>

      <div className="flex flex-col flex-grow">
        <div className="flex h-[56px] overflow-x-scroll md:grid md:grid-cols-2 md:h-full gap-[16px]">
          <div
            className={`${currentAnalysis === 'summary' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] col-span-2 rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('summary')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <DocumentIcon className="stroke-white" />
              Summary
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'food' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('food')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <FoodIcon className="fill-white opacity-50" />
              Food
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'drinks' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('drinks')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <DrinkIcon className="fill-white opacity-50" />
              Drinks
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'service' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('service')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <ServiceIcon className="fill-white opacity-50" />
              Service
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'price' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('price')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <PriceIcon className="stroke-white" />
              Price
            </p>
          </div>

          <button
            onClick={onOpportunitiesClicked}
            className={`${isProUser ? 'bg-r-gray-light' : 'bg-r-gray-light bg-opacity-60 text-[#808EA5]'} min-w-fit md:w-auto px-[18px] col-span-2 rounded-md text-white flex items-center justify-center cursor-pointer`}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <LightBulbIcon className={`${isProUser ? "stroke-white" : 'stroke-[#808EA5]'} h-[21px]`} />
              Opportunities
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default RestaurantInfo;
