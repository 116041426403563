import { ReactComponent as CloseIcon } from '../assets/close-icon.svg';

const PrivacyPolicy = ({ onCloseClicked }) => {
  return (
    <div className="w-[900px] max-h-[80%] bg-[#1D2839] p-[32px] rounded-lg overflow-scroll">
      <div className="flex justify-between items-center mb-[8px]">
        <p className="font-medium text-[30px] text-white"></p>

        <button className="text-[#9CABC4]" onClick={onCloseClicked}>
          <CloseIcon className="stroke-[#9CABC4] hover:stroke-[#18A6C5]" />
        </button>
      </div>

      <div className="text-white">
        <div className='text-center mb-8'>
          <h1 className="text-[20px] font-semibold mb-2">Privacy Policy for RestaurantReport.ai</h1>
          <p className="text-[14px] font-medium">Effective Date: 10/14/2024</p>
        </div>

        <p className="text-[14px] font-medium mt-4 mb-2">
          At <em>RestaurantReport.ai</em> (“we,” “us,” or “our”), we value your privacy and are committed to
          safeguarding the personal information you entrust to us. This Privacy Policy (the “Policy”)
          outlines the types of information we collect, how we use and protect that information, and the
          rights and choices available to you regarding your personal information. By accessing or using
          www.restaurantreport.ai (the “Website”) or our associated services (the “Service”), you consent
          to the terms of this Policy.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">1. Scope of This Policy</h2>
        <p className="text-[14px] font-medium">
          This Policy applies to information collected through the Website and the Service. It does not
          apply to third-party websites, products, or services that may be linked to on the Website. We
          encourage you to review the privacy policies of any third-party services before providing your
          personal information to them.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">2. Information We Collect</h2>
        <p className="text-[14px] font-medium">
          We collect both personal and non-personal information to provide, improve, and operate our
          Service.
        </p>

        <h3 className="text-[18px] font-semibold mb-2 mt-3">2.1 Personal Information</h3>
        <p className="text-[14px] font-medium">
          We collect the following personal information when you register for and use our Service:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            <strong>Account Information</strong>: Information you provide during account creation, including your name,
            email address, and payment details.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Communications</strong>: If you contact us for support or with inquiries, we collect the content of
            your communication and your contact information to respond to you.
          </li>
        </ul>

        <h3 className="text-[18px] font-semibold mb-2 mt-3">2.2 Non-Personal Information</h3>
        <p className="text-[14px] font-medium">
          We collect technical and usage data to enhance the functionality and performance of the
          Website:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            <strong>Log Data</strong>: Information automatically collected when you interact with our Website, such as
            your IP address, browser type, operating system, referring/exit pages, and timestamps of your
            visits.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Cookies</strong>: We use cookies and other tracking technologies to enhance your experience on
            the Website. These technologies collect information about how you navigate and interact with
            the Service.
          </li>
        </ul>

        <h3 className="text-[18px] font-semibold mb-2 mt-3">2.3 Review Data</h3>
        <p className="text-[14px] font-medium">
          The core functionality of the Service is based on the collection of publicly available customer
          reviews from third-party platforms (such as Google Reviews, Grubhub, Uber Eats, and
          DoorDash). This data is aggregated and analyzed to provide insights to restaurant owners. We
          do not link this data to your personal information.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">3. How We Use Your Information</h2>
        <p className="text-[14px] font-medium">
          We use the information we collect to:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            <strong>Provide and Operate the Service</strong>: We use your personal information to create and manage
            your account, process payments, and deliver the insights generated by our AI analysis.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Communicate with You</strong>: We use your contact information to send you important updates
            about your account, respond to your inquiries, and send promotional content (which you may
            opt out of).
          </li>
          <li className="text-[14px] font-medium">
            <strong>Improve Our Service</strong>: We analyze the usage data to improve the functionality, performance,
            and user experience of our Website.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Ensure Security and Compliance</strong>: We use your data to monitor, detect, and prevent any
            suspicious or unauthorized activities on our platform, and to comply with applicable legal
            requirements.
          </li>
        </ul>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">4. Legal Basis for Processing Personal Information</h2>
        <p className="text-[14px] font-medium">
          We rely on the following legal bases for processing your personal information:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            <strong>Contractual Necessity</strong>: Processing your personal information is necessary to provide you
            with the Service and to fulfill our obligations under the terms of service.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Legitimate Interests</strong>: We process certain information to further our legitimate business
            interests, such as improving the Service and ensuring its security.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Legal Obligations</strong>: We may process your personal information to comply with applicable
            laws and regulations.
          </li>
        </ul>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">5. Data Sharing and Disclosure</h2>
        <p className="text-[14px] font-medium">
          We do not sell or rent your personal information to third parties. We may share your information
          with:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            <strong>Service Providers</strong>: We may engage third-party service providers to assist in providing the
            Service, such as payment processors and hosting services. These providers are contractually
            obligated to protect your information and use it solely in accordance with our instructions.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Legal Obligations</strong>: We may disclose your personal information if required by law, in
            response to a legal process, or to protect our rights or the rights of others, including to prevent
            fraud or enforce our agreements.
          </li>
        </ul>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">6. Data Retention</h2>
        <p className="text-[14px] font-medium">
          We retain your personal information for as long as your account remains active or as needed to
          provide you with the Service. We may retain certain information for a longer period to comply
          with legal obligations, resolve disputes, or enforce our agreements. Once the information is no
          longer necessary, we will delete or anonymize it in accordance with our data retention policies.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">7. Data Security</h2>
        <p className="text-[14px] font-medium">
          We implement appropriate technical and organizational measures to safeguard your personal
          information from unauthorized access, disclosure, alteration, and destruction. These measures
          include encryption of sensitive data and access controls. However, no system can be entirely
          secure, and we cannot guarantee the absolute security of your information.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">8. Your Rights and Choices</h2>
        <p className="text-[14px] font-medium">
          You have the following rights regarding your personal information:
        </p>
        <ul className="list-disc pl-5 my-3">
          <li className="text-[14px] font-medium">
            <strong>Access and Correction</strong>: You may access and update your personal information by logging
            into your account or contacting us.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Data Deletion</strong>: You may request that we delete your account and personal information.
            Please note that certain information may be retained as required by law or for legitimate
            business purposes.
          </li>
          <li className="text-[14px] font-medium">
            <strong>Opt-Out of Communications</strong>: You may opt out of receiving promotional communications
            from us by following the unsubscribe instructions in our emails or contacting us directly.
          </li>
        </ul>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">9. Cookies and Tracking Technologies</h2>
        <p className="text-[14px] font-medium">
          We use cookies and other tracking technologies to personalize your experience, analyze
          Website usage, and assist with our promotional efforts. You can control the use of cookies
          through your browser settings, but disabling cookies may affect the functionality of the Service.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">10. Third-Party Links</h2>
        <p className="text-[14px] font-medium">
          Our Website may contain links to third-party websites. We are not responsible for the privacy
          practices or the content of these external sites. We recommend reviewing the privacy policies of
          any linked websites before sharing your personal information.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">11. International Data Transfers</h2>
        <p className="text-[14px] font-medium">
          If you access the Website from outside the United States, please be aware that your personal
          information may be transferred to, processed, and stored in the United States, where our
          servers and central database are located. By using the Website, you consent to such transfer
          and processing in the U.S., where privacy laws may differ from those in your jurisdiction.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">12. Children's Privacy</h2>
        <p className="text-[14px] font-medium">
          The Service is not intended for individuals under the age of 18, and we do not knowingly collect
          personal information from minors. If we learn that we have inadvertently collected personal
          information from a minor, we will take steps to delete the information as soon as possible.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">13. Changes to This Policy</h2>
        <p className="text-[14px] font-medium">
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal
          requirements. We will notify you of any significant changes by posting the updated Policy on the
          Website and indicating the effective date. We encourage you to review this Policy periodically to
          stay informed about how we are protecting your information.
        </p>

        <h2 className="text-[20px] font-semibold mb-2 mt-4">14. Contact Information</h2>
        <p className="text-[14px] font-medium">
          If you have any questions about this Privacy Policy or our data practices, please contact us at
          info@restaurantreport.ai.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
