import { differenceInHours } from 'date-fns/differenceInHours';
import { differenceInDays } from 'date-fns/differenceInDays';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { differenceInYears } from 'date-fns/differenceInYears';

import { ReactComponent as StarIcon } from '../assets/star-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/close-icon.svg';

const ReviewItem = ({ review }) => {
  const currentDate = new Date();
  const reviewDate = new Date(review.date);

  let intervalSign = 'h';
  let intervalValue = 0;

  intervalValue = differenceInHours(currentDate, reviewDate);

  if (intervalValue > 24 && intervalValue < 30 * 24) {
    intervalValue = differenceInDays(currentDate, reviewDate);
    intervalSign = 'd';
  } else if (intervalValue > 30 * 24 && intervalValue < 365 * 24) {
    intervalValue = differenceInMonths(currentDate, reviewDate);
    intervalSign = 'mo';
  } else if (intervalValue > 365 * 24) {
    intervalValue = differenceInYears(currentDate, reviewDate);
    intervalSign = 'y';
  }

  return (
    <div className="flex flex-col bg-[#1D2839] rounded-lg text-white gap-2 w-full">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <div className='bg-slate-500 rounded-full w-[24px] h-[24px]'>
            {/* <img src={review.author.photoUrl} /> */}
          </div>

          <div className="flex flex-col">
            <div className="flex flex-row items-center font-figtree gap-2 mb-[6px]">
              <p className='font-semibold text-[15px] leading-4 font-figtree'>{review?.author?.name}</p>
              <p className='text-[13px] text-[#737D8A] leading-4 font-figtree'>{intervalValue}{intervalSign} ago</p>
            </div>

            <div className="flex flex-row items-center gap-2">
              {review.rating && (
                Array.from({ length: review.rating }).map((_, index) => (
                  <StarIcon key={index} className="fill-[#F0B64D] w-[11px] h-[10px]" />
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <pre className='text-white text-[16px] font-figtree font-semibold leading-[25px] whitespace-pre-line'>
        {review.text}
      </pre>
    </div>
  )
}

const RelatedReviewsList = ({ reviews, onCloseClicked }) => {
  return (
    <div className='w-[900px] max-h-[80%] bg-[#1D2839] p-[32px] rounded-lg overflow-scroll flex flex-col gap-4'>
      <div className="flex justify-between items-center mb-[8px]">
        <p className="font-medium text-[30px] text-white">Related reviews</p>

        <button className="text-[#9CABC4]" onClick={onCloseClicked}>
          <CloseIcon className="stroke-[#9CABC4] hover:stroke-[#18A6C5]" />
        </button>
      </div>

      <div className='flex flex-col gap-6'>
        {reviews?.map((review) => (
          <ReviewItem review={review} key={review._id} />
        ))}
      </div>
    </div>
  )
}

export default RelatedReviewsList;
