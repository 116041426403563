import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { AuthContext } from "./AuthContext";

import { SEARCH_NETWORKS } from "../constants";

import useFetchReviewCountsbyTopic from "../hooks/useFetchReviewCountsbyTopic";
import useInterval from "../hooks/useInterval";
import apiService from "../services/apiService";

export const RestaurantContext = createContext();

export const RestaurantProvider = ({ children }) => {
  const { user, setUser, token } = useContext(AuthContext);

  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  const [userRestaurant, setUserRestaurant] = useState(null);
  const [isLoadingRestaurant, setIsLoadingRestaurant] = useState(false);
  const [hasFetchedRestaurant, setHasFetchedRestaurant] = useState(false);

  const [snapshots, setSnapshots] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [isLoadingSnapshots, setIsLoadingSnapshots] = useState(false);
  const [hasFetchedSnapshots, setHasFetchedSnapshots] = useState(false);

  const [instagramCommentCount, setInstagramCommentCount] = useState(null);
  const [isFetchingInstagramCommentCount, setIsFetchingInstagramCommentCount] = useState(false);
  const [hasFetchedInstagramCommentCount, setHasFetchedInstagramCommentCount] = useState(false);

  const [savedRatingsMap, setSavedRatingsMap] = useState(null);
  const [startedFetchingRatingMap, setStartedFetchingRatingMap] = useState(false);

  const [opportunities, setOpportunities] = useState(null);

  const [isProcessingResults, setIsProcessingResults] = useState(false);
  const [isProcessingInstagramResults, setIsProcessingInstagramResults] = useState(false);

  const [selectOptions, setSelectOptions] = useState([]);

  const networks = SEARCH_NETWORKS.map(network => network.value);

  const availableNetworks = networks.reduce((acc, network) => {
    if (!selectedRestaurant) return acc;

    if (network === 'instagram' && selectedRestaurant?.instagram?.posts) {
      return [...acc, 'instagram'];
    } else if (selectedRestaurant?.[network]?.id) {
      return [...acc, network];
    }

    return acc;
  }, []);

  const resetLoadedRestaurant = () => {
    setSavedRatingsMap(null);
    setStartedFetchingRatingMap(false);
    setOpportunities(null);
    setInstagramCommentCount(null);
    setIsFetchingInstagramCommentCount(false);
    setHasFetchedInstagramCommentCount(false);
    setStartedFetchingReviewsByTopic(false);
  }

  const isCreatingSnapshot = user?.creatingSnapshots?.length > 0;

  useInterval(() => {
    const updateUser = async () => {
      const response = await apiService.fetchUserFromToken({ token });

      setUser(response.data);
    }

    const fetchSnapshots = async () => {
      const response = await apiService.fetchUserSnapshots({ userId: user._id });

      const snapshots = response.data;

      for (const snapshot of snapshots) {
        snapshot.restaurant.isSnapshot = true;
      }

      await updateUser();

      setSnapshots(snapshots);
      setIsLoadingSnapshots(false);
      setHasFetchedSnapshots(true);
    };

    setIsLoadingSnapshots(true);
    fetchSnapshots();
  }, isCreatingSnapshot ? 30 * 1000 : null);

  useEffect(() => {
    const fetchRestaurant = async () => {
      const response = await apiService.fetchUserRestaurant({ userId: user._id });

      setUserRestaurant(response.data);
      setSelectedRestaurant(response.data);
      setIsLoadingRestaurant(false);
      setHasFetchedRestaurant(true);
    };

    if (user && !userRestaurant && !isLoadingRestaurant && !hasFetchedRestaurant) {
      setIsLoadingRestaurant(true);
      fetchRestaurant();
    }
  }, [user, userRestaurant, isLoadingRestaurant, hasFetchedRestaurant]);

  useEffect(() => {
    let options = [];

    if (snapshots) {
      for (const snapshot of snapshots) {
        const snapshotDate = new Date(snapshot.dateCreated).toLocaleDateString();

        options.push({
          value: snapshot.restaurant._id,
          label: snapshot.restaurant.google.name,
          restaurant: snapshot.restaurant,
          snapshotDate
        });
      }
    }

    if (user?.creatingSnapshots) {
      for (const snapshot of user.creatingSnapshots) {
        options.unshift({
          value: snapshot.snapshotName,
          label: snapshot.snapshotName,
          isCreatingSnapshot: true,
          isDisabled: true
        });
      }
    }

    if (userRestaurant) {
      options.unshift({ value: userRestaurant?._id, label: userRestaurant?.google.name, restaurant: userRestaurant });
    }

    setSelectOptions(options);
  }, [snapshots, userRestaurant, user, isCreatingSnapshot]);

  useEffect(() => {
    const isProcessing = networks.filter(network => network !== 'instagram').reduce((acc, network) => {
      if (acc) return acc;

      if (!selectedRestaurant) return false;

      if (!selectedRestaurant?.[network]?.id) return false;

      if (!selectedRestaurant?.analysis?.summaryUpdatedOn || !selectedRestaurant?.analysis?.topicsUpdatedOn) return true;

      if (selectedRestaurant?.[network]?.isProcessingResults) return true;

      return acc;
    }, false);

    if (selectedRestaurant && selectedRestaurant?.instagram?.slug && selectedRestaurant?.instagram?.isProcessingResults) {
      setIsProcessingInstagramResults(true);
    } else {
      setIsProcessingInstagramResults(false);
    }

    setIsProcessingResults(isProcessing);
  }, [selectedRestaurant, networks]);

  const { topicReviews, setStartedFetchingReviewsByTopic } = useFetchReviewCountsbyTopic(selectedRestaurant, availableNetworks, isProcessingResults);

  useEffect(() => {
    const fetchInstagramCommentCount = async () => {
      const response = await apiService.fetchInstagramCommentCount({ restaurantId: selectedRestaurant._id });

      setInstagramCommentCount(response.data);
      setIsFetchingInstagramCommentCount(false);
      setHasFetchedInstagramCommentCount(true);
    };

    if (selectedRestaurant && availableNetworks.includes('instagram') && !isFetchingInstagramCommentCount && !hasFetchedInstagramCommentCount && !isProcessingInstagramResults) {
      setIsFetchingInstagramCommentCount(true);
      fetchInstagramCommentCount();
    }
  }, [selectedRestaurant, availableNetworks, isProcessingInstagramResults, hasFetchedInstagramCommentCount, isFetchingInstagramCommentCount]);

  useEffect(() => {
    const fetchSnapshots = async () => {
      const response = await apiService.fetchUserSnapshots({ userId: user._id });

      const snapshots = response.data;

      for (const snapshot of snapshots) {
        snapshot.restaurant.isSnapshot = true;
      }

      setSnapshots(snapshots);
      setIsLoadingSnapshots(false);
      setHasFetchedSnapshots(true);
    };

    if (user && !isLoadingSnapshots && !hasFetchedSnapshots) {
      setIsLoadingSnapshots(true);
      fetchSnapshots();
    }
  }, [user, snapshots, isLoadingSnapshots, hasFetchedSnapshots]);

  useEffect(() => {
    if (isProcessingResults) {
      setStartedFetchingRatingMap(false);
      setSavedRatingsMap(null);
    }

    if (selectedRestaurant && !savedRatingsMap && !startedFetchingRatingMap && !isProcessingResults) {
      setStartedFetchingRatingMap(true);

      const fetchRatingMap = async () => {
        try {
          const response = await apiService.fetchReviewRatingsMap({ restaurantId: selectedRestaurant._id });

          setSavedRatingsMap(response.data);

        } catch (error) {
          toast.error(error?.response?.data?.message || 'An error occurred while fetching rating map.');
        }
      }

      fetchRatingMap();
    }
  }, [selectedRestaurant, savedRatingsMap, startedFetchingRatingMap, isProcessingResults]);

  useInterval(() => {
    const fetchRestaurant = async () => {
      const { data: fetchedRestaurant } = await apiService.fetchRestaurant({ restaurantId: selectedRestaurant._id });

      setSelectedRestaurant(fetchedRestaurant);
    }

    fetchRestaurant();
  }, (isProcessingResults || isProcessingInstagramResults) ? 10 * 1000 : null);

  const isGeneratingSummaries = !selectedRestaurant?.analysis?.summaryUpdatedOn || !selectedRestaurant?.analysis?.topicsUpdatedOn;

  return (
    <RestaurantContext.Provider value={{
      restaurant: selectedRestaurant,
      setRestaurant: setSelectedRestaurant,
      selectedSnapshot,
      setSelectedSnapshot,
      setHasFetchedSnapshots,
      isLoadingRestaurant,
      hasFetchedRestaurant,
      isProcessingResults,
      resetLoadedRestaurant,
      searchNetworks: SEARCH_NETWORKS,
      availableNetworks,
      instagramCommentCount,
      savedRatingsMap,
      topicReviewCounts: topicReviews,
      opportunities,
      setOpportunities,
      selectOptions,
      isGeneratingSummaries,
      isFetchingInstagramCommentCount,
      setIsProcessingInstagramResults
    }}>
      {children}
    </RestaurantContext.Provider>
  );
};