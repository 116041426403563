import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';

const OpportunityDetails = ({ opportunity, onCloseClicked }) => {
  return (
    <div className='w-[900px] max-h-[80%] bg-[#1D2839] p-[32px] rounded-lg overflow-scroll flex flex-col gap-4'>
      <div className="flex justify-between items-center mb-[8px]">
        <p className="font-semibold text-white pr-4 text-lg">{opportunity.title}</p>

        <button className="text-[#9CABC4]" onClick={onCloseClicked}>
          <CloseIcon className="stroke-[#9CABC4] hover:stroke-[#18A6C5]" />
        </button>
      </div>

      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-10'>
          {opportunity.steps.map((step, index) => (
            <div className='flex flex-col gap-1 text-white'>
              <p className='text-xl font-semibold mb-3'>{index + 1}. {step.title}</p>
              <p className='font-medium mb-3'>{step.description}</p>

              <div className='pl-8'>
                {step.substeps.map((substep, index) => (
                  <div className='flex flex-col gap-1'>
                    <p className='font-medium'>- {substep}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OpportunityDetails;
