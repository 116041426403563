import Highlighter from "react-highlight-words";
import differenceInHours from 'date-fns/differenceInHours';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInYears from 'date-fns/differenceInYears';

import { ReactComponent as StarIcon } from '../../assets/star-icon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google-logo.svg';
import { ReactComponent as YelpIcon } from '../../assets/yelp-icon.svg';
import { ReactComponent as GrubhubIcon } from '../../assets/grubhub-logo.svg';
import { ReactComponent as DoordashIcon } from '../../assets/doordash-logo.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram-logo.svg';
import { ReactComponent as UberEatsIcon } from '../../assets/uber-eats-logo.svg';
import tripAdvisorIcon from '../../assets/trip-advisor-logo.png';
import openTableIcon from '../../assets/opentable-logo.png';

const ReviewNetworkIcon = (network) => {
  switch (network) {
    case 'google': return <GoogleIcon className="h-[32px] w-[32px] rounded-md" />;
    case 'yelp': return <YelpIcon className="h-[32px] w-[32px] rounded-md" />;
    case 'grubhub': return <GrubhubIcon className="h-[32px] w-[32px] rounded-md" />;
    case 'doordash': return <DoordashIcon className="h-[32px] w-[32px] rounded-md" />;
    case 'instagram': return <InstagramIcon className="h-[32px] w-[32px] rounded-md" />;
    case 'tripadvisor': return <img src={tripAdvisorIcon} className="h-[32px] w-[32px] rounded-md" alt="tripadvisor" />;
    case 'opentable': return <div className="bg-white p-[5px] rounded-md h-[32px] w-[32px]"><img src={openTableIcon} className="rounded-md" alt="opentable" /></div>;
    case 'ubereats': return <UberEatsIcon className="h-[32px] w-[32px] rounded-md" />;

    default: return null;
  }
}

const ReviewListItem = ({ review, searchTerm }) => {
  const currentDate = new Date();
  const reviewDate = new Date(review.date);

  let intervalSign = 'h';
  let intervalValue = 0;

  intervalValue = differenceInHours(currentDate, reviewDate);

  if (intervalValue > 24 && intervalValue < 30 * 24) {
    intervalValue = differenceInDays(currentDate, reviewDate);
    intervalSign = 'd';
  } else if (intervalValue > 30 * 24 && intervalValue < 365 * 24) {
    intervalValue = differenceInMonths(currentDate, reviewDate);
    intervalSign = 'mo';
  } else if (intervalValue > 365 * 24) {
    intervalValue = differenceInYears(currentDate, reviewDate);
    intervalSign = 'y';
  }

  return (
    <div className="flex flex-col p-[24px] bg-[#1D2839] rounded-lg text-white gap-2 w-full">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <div className='bg-slate-500 rounded-full w-[48px] h-[48px]'>
            {/* <img src={review.author.photoUrl} /> */}
          </div>

          <div className="flex flex-col">
            <div className="flex flex-row items-center font-figtree gap-2 mb-[6px]">
              <p className='font-semibold text-[16px] leading-0'>{review?.author?.name || review?.user}</p>
              <p className='text-[14px] text-[#737D8A] leading-0'>{intervalValue}{intervalSign} ago</p>
            </div>

            <div className="flex flex-row items-center gap-2">
              {review.rating && (
                Array.from({ length: review.rating }).map((_, index) => (
                  <StarIcon key={index} className="fill-[#F0B64D] w-[11px] h-[10px]" />
                ))
              )}
            </div>
          </div>
        </div>

        {ReviewNetworkIcon(review.network ? review.network : review.user ? 'instagram' : null)}
      </div>

      <div className="text-white text-[16px] font-figtree font-semibold leading-[25px] break-words">
        <Highlighter
          highlightClassName="text-r-blue bg-transparent"
          searchWords={[searchTerm]}
          autoEscape={true}
          textToHighlight={review.text}
        />
      </div>
    </div>
  )
}

export default ReviewListItem;
