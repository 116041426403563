import { ReactComponent as InstagramIcon } from '../../assets/instagram-logo.svg';
import { ReactComponent as CommentIcon } from '../../assets/comment-icon.svg';

import Loader from '../Loader';

const InstagramNetworkListItem = ({ postCount, commentCount, isLoading, isConnected, onConnectClicked }) => {
  return (
    <div className="flex justify-between items-center gap-4 p-[16px] rounded-lg bg-[#202731]">
      <div className='flex items-center gap-2'>
        <InstagramIcon className="h-[24px]" />

        <p className="text-[16px] text-white font-semibold">
          Instagram
          {isConnected && (
            <span className='text-[14px] text-[#728096] font-semibold leading-none ml-2'>
              ({postCount} Posts)
            </span>
          )}

        </p>
      </div>

      <div className='flex items-center gap-1'>
        {(isLoading) && (
          <Loader isSmall={true} />
        )}

        {isConnected && (
          <>
            <CommentIcon className="fill-r-blue w-[24px]" />
            <p className="text-[24px] text-white font-semibold leading-none">{commentCount}</p>
          </>
        )}

        {(!isConnected && !isLoading) && (
          <button
            className='bg-r-blue text-white font-semibold px-4 py-1 rounded-md'
            onClick={onConnectClicked}
          >
            Connect
          </button>
        )}
      </div>
    </div>
  )
}

export default InstagramNetworkListItem;
