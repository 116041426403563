const UserMessage = ({ message }) => {
  return (
    <div className="bg-[#2E3643] rounded-md max-w-[450px] text-white self-end p-[16px] mb-[48px]">
      <p className="font-sans font-medium text-[15px] leading-[24px]">
        {message.text}
      </p>
    </div>
  )
}

export default UserMessage;
