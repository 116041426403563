import { ReactComponent as StarIcon } from '../../assets/star-icon.svg';

import searchNetworks from '../../data/searchNetworks.json';

import NetworkIcon from './NetworkIcon';

const NetworkListItem = ({ networkSlug, totalReviews, averageRating }) => {
  return (
    <div className="flex justify-between items-center gap-4 p-[16px] rounded-lg bg-[#202731]">
      <div className='flex items-center gap-2'>
        <NetworkIcon network={networkSlug} />

        <p className="text-[16px] text-white font-semibold">
          {searchNetworks.find(network => network.value === networkSlug).name}
          <span className='text-[14px] text-[#728096] font-semibold leading-none ml-2'>
            ({totalReviews} Reviews)
          </span>
        </p>
      </div>

      <div className='flex items-center gap-1'>
        <StarIcon className="fill-r-blue w-[24px]" />
        <p className="text-[24px] text-white font-semibold leading-none">{averageRating}</p>
      </div>
    </div>
  )
}

export default NetworkListItem;
