import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../components/AuthContext";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { RestaurantProvider } from "../components/RestaurantContext";

import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import MobileMenuOverlay from "../components/MobileMenuOverlay";
import Modal from '../components/Modal';
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsOfService from "../components/TermsOfService";
import UpgradeToPro from "../components/UpgradeToPro";

const DashboardLayout = () => {
  const { loading, token, isUpgradeToProOpen, setIsUpgradeToProOpen } = useContext(AuthContext);

  const [isMobileNavOverlayOpen, setIsMobileNavOverlayOpen] = useState(false);

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsMobileNavOverlayOpen(false);
  }, [location])

  const handlePrivacyPolicyClicked = () => {
    setIsPrivacyModalOpen(true);
    setIsTermsModalOpen(false);
  };

  const handleTermsOfServiceClicked = () => {
    setIsTermsModalOpen(true);
    setIsPrivacyModalOpen(false);
  };

  const handleUpgradeClicked = () => {
    setIsUpgradeToProOpen(false);
    navigate('/plans');
  };

  if (!token && !loading) {
    return <Navigate to="/login" replace />;
  }

  return (
    <RestaurantProvider>
      <div className="flex flex-col md:flex-row min-h-screen relative">
        <Navbar
          onPrivacyPolicyClicked={handlePrivacyPolicyClicked}
          onTermsOfServiceClicked={handleTermsOfServiceClicked}
        />

        <MobileNavbar onBurgerMenuClicked={e => setIsMobileNavOverlayOpen(true)} />

        <MobileMenuOverlay
          isOpen={isMobileNavOverlayOpen}
          onCloseClicked={e => setIsMobileNavOverlayOpen(false)}
          onPrivacyPolicyClicked={handlePrivacyPolicyClicked}
          onTermsOfServiceClicked={handleTermsOfServiceClicked}
        />

        <main className='flex flex-col md:flex-row flex-grow md:pl-[104px] bg-[#161F2E]'>
          <Outlet />
        </main>

        <Modal isOpen={isPrivacyModalOpen}>
          <PrivacyPolicy onCloseClicked={e => setIsPrivacyModalOpen(false)} />
        </Modal>

        <Modal isOpen={isTermsModalOpen}>
          <TermsOfService onCloseClicked={e => setIsTermsModalOpen(false)} />
        </Modal>

        <Modal isOpen={isUpgradeToProOpen}>
          <UpgradeToPro
            onUpgradeClicked={handleUpgradeClicked}
            onCancelClicked={e => setIsUpgradeToProOpen(false)}
          />
        </Modal>
      </div>
    </RestaurantProvider>
  )
}

export default DashboardLayout;
