import { useEffect, useRef } from "react";

import LoadingMessage from "./LoadingMessage";
import SystemMessage from "./SystemMessage";
import UserMessage from "./UserMessage";

const Chat = ({ messages, onViewReviewsClicked }) => {
  const lastMessageDivRef = useRef(null);

  useEffect(() => {
    lastMessageDivRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex flex-col flex-grow h-full py-[50px] w-full md:w-[900px] px-[16px] md:px-0">
      <div className="flex flex-col">
        {messages.map((message, index) => {
          if (message.type === 'user') {
            return <UserMessage key={`message-${index}`} message={message} />
          } else if (message.type === 'system') {
            return <SystemMessage
              key={`message-${index}`} message={message} onViewReviewsClicked={onViewReviewsClicked} />
          } else {
            return <LoadingMessage key={`message-${index}`} />
          }
        })}

        <div ref={lastMessageDivRef}></div>
      </div>
    </div>
  )
};

export default Chat;
