import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";

import DashboardLoader from "./DashboardLoader";
import ReviewListItem from "./Search/ReviewListItem";
import NoResultSearchForm from "./Search/NoResultSearchForm";
import ResultSearchForm from "./Search/ResultSearchForm";
import Loader from "./Loader";
import Pagination from './Pagination';

import { ReactComponent as CaretIcon } from '../assets/caret-icon.svg';

import { AuthContext } from "./AuthContext";
import { RestaurantContext } from "./RestaurantContext";
import apiService from "../services/apiService";
import RestaurantIndicator from "./RestaurantIndicator";

const sortOptions = [{
  name: 'Date',
  value: 'date'
}, {
  name: 'Rating',
  value: 'rating'
}];

const NoResultSearch = ({ onSearch }) => {
  return (
    <div className="flex flex-col items-center justify-center flex-grow p-[16px] md:p-0">
      <h1 className="font-medium text-[30px] leading-[32px] md:text-[48px] text-center text-white mb-[8px] md:mb-[24px]">Search Reviews</h1>

      <p className="font-medium text-[15px] md:text-[20px] text-center text-[#728096] mb-[8px] md:mb-[72px]">
        Browse all reviews, search by keyword, or filter by platform and topic
      </p>

      <NoResultSearchForm onSearch={onSearch} />
    </div>
  )
}

const ResultSearch = ({ onSearch, reviews, searchTerm, preselectedNetworks, preselectedTopics, preselectedSort, preselectedSortDirection, isLoadingResults, resultCount, currentPage, totalPages }) => {
  const [sortDirection, setSortDirection] = useState(preselectedSortDirection);
  const [selectedSort, setSelectedSort] = useState(preselectedSort);

  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  const handlePaginationChanged = (page) => {
    onSearch({
      text: searchTerm,
      networks: preselectedNetworks,
      topics: preselectedTopics,
      sort: selectedSort,
      sortDirection: preselectedSortDirection,
      searchPage: page
    });
  }

  const handleSortChanged = (sort) => {
    setSelectedSort(sort);
    setIsSortDropdownOpen(false);

    onSearch({
      text: searchTerm,
      networks: preselectedNetworks,
      topics: preselectedTopics,
      sort,
      sortDirection,
      page: currentPage
    });
  }

  const handleSortDirectionClicked = () => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    onSearch({
      text: searchTerm,
      networks: preselectedNetworks,
      topics: preselectedTopics,
      sort: selectedSort,
      sortDirection: newSortDirection,
      searchPage: currentPage
    });
  }

  const handleSearch = ({ text, networks, topics }) => {
    onSearch({ text, networks, topics, sort: selectedSort, sortDirection, searchPage: currentPage });
  }

  return (
    <div className="flex flex-col items-center flex-grow p-[16px] md:p-[50px]">
      <div className="flex flex-col items-center w-full max-w-auto md:max-w-[850px]">
        <div className="mb-[32px] w-full">
          <ResultSearchForm
            onSearch={handleSearch}
            searchTerm={searchTerm}
            preselectedNetworks={preselectedNetworks}
            preselectedTopics={preselectedTopics}
          />
        </div>

        <div className="flex flex-row justify-between w-full mb-[16px]">
          <p className="font-medium text-[12px] md:text-[18px] leading-[24px]">
            {
              totalPages > 1
                ? <span className="text-r-blue">
                  {(currentPage - 1) * 50}
                  <span className="text-white"> to </span>
                  {currentPage === totalPages ? (currentPage - 1) * 50 + reviews.length : currentPage * 50}
                  <span className="text-white"> out of</span> {resultCount}
                </span>
                : <span className="text-r-blue">{resultCount}</span>
            }

            <span className="text-white"> Results</span>
          </p>

          <div className="relative">
            <p className="flex flex-row items-center text-[16px] leading-[24px] font-medium select-none">
              <span className="cursor-pointer">
                <span className="text-[#728096] mr-1">Sort By:</span>
                <span
                  className="text-white mr-3 hover:text-r-blue transition-all"
                  onClick={e => setIsSortDropdownOpen(!isSortDropdownOpen)}
                >
                  {selectedSort.name}
                </span>
              </span>

              <CaretIcon
                onClick={handleSortDirectionClicked}
                className={`${sortDirection === 'asc' ? 'rotate-180' : ''} stroke-[#728096] cursor-pointer hover:stroke-r-blue transition-all`}
              />
            </p>

            <div className={`${isSortDropdownOpen ? 'flex' : 'hidden'} absolute top-[115%] gap-[13px] flex-col p-[16px] w-full rounded-md bg-r-gray-dark text-white text-[16px] leading-[24px] font-medium shadow-sm`}>
              {sortOptions.map((option) => (
                <p
                  key={`sort-${option.value}`}
                  className={`${selectedSort.value === option.value ? 'text-r-blue' : ''} cursor-pointer hover:text-r-blue transition-all`}
                  onClick={e => handleSortChanged(option)}
                >{option.name}</p>
              ))}
            </div>
          </div>
        </div>

        {
          isLoadingResults
            ? (
              <div className="flex flex-col items-center justify-center flex-grow z-20 py-[30px]">
                <Loader />
              </div>
            )
            : (
              <div className="flex flex-col gap-4 w-full">
                {reviews.map((review) => (
                  <ReviewListItem
                    review={review}
                    searchTerm={searchTerm}
                    key={review._id}
                  />
                ))}

                {totalPages > 1 && <Pagination page={currentPage} pages={totalPages} onClick={handlePaginationChanged} />}
              </div>
            )
        }
      </div>
    </div>
  )
}

const Search = () => {
  const { user } = useContext(AuthContext);
  const { restaurant, isLoadingRestaurant, hasFetchedRestaurant } = useContext(RestaurantContext);

  const [reviews, setReviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedSortDirection, setSelectedSortDirection] = useState('desc');
  const [resultCount, setResultCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  if (!restaurant && hasFetchedRestaurant) {
    return <Navigate to="/find-restaurant" replace />;
  }

  if (user && user.subscription.status === 'canceled') {
    return <Navigate to="/profile" replace />;
  }

  if (user && new Date(user?.subscription?.current_period_end * 1000) < new Date()) {
    return <Navigate to="/profile" replace />;
  }

  const handleSearch = async ({ text, networks, topics, sort, sortDirection, searchPage }) => {
    setIsLoadingResults(true);
    setIsFirstSearch(false);

    const response = await apiService.searchReviews({
      restaurantId: restaurant._id,
      text,
      networks,
      topics,
      sort: sort.value,
      sortDirection,
      page: searchPage
    });

    const { reviews, count, page, limit } = response.data[0];

    setCurrentPage(page);
    setTotalPages(Math.ceil((count[0] ? count[0]?.total : 0) / limit));
    setIsLoadingResults(false);
    setReviews(reviews);
    setResultCount(count[0] ? count[0]?.total : 0);
    setSelectedNetworks(networks);
    setSelectedTopics(topics);
    setSelectedSort(sort);
    setSelectedSortDirection(sortDirection);
    setSearchTerm(text);
  }

  return (
    <>
      <Helmet>
        <title>Search Reviews | Restaurant Report</title>
      </Helmet>

      {
        isLoadingRestaurant || !user
          ? <DashboardLoader />
          : (
            isFirstSearch
              ? <NoResultSearch onSearch={handleSearch} />
              : <ResultSearch
                onSearch={handleSearch}
                reviews={reviews}
                resultCount={resultCount}
                searchTerm={searchTerm}
                preselectedNetworks={selectedNetworks}
                preselectedTopics={selectedTopics}
                preselectedSort={selectedSort}
                preselectedSortDirection={selectedSortDirection}
                isLoadingResults={isLoadingResults}
                currentPage={currentPage}
                totalPages={totalPages}
              />
          )
      }

      <RestaurantIndicator />
    </>
  );
}

export default Search;
