import { ReactComponent as UberEatsIcon } from '../../assets/uber-eats-logo.svg';
import { ReactComponent as DoordashIcon } from '../../assets/doordash-logo.svg';
import { ReactComponent as GrubhubIcon } from '../../assets/grubhub-logo.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google-logo.svg';
import { ReactComponent as YelpIcon } from '../../assets/yelp-icon.svg';
import tripAdvisorIcon from '../../assets/trip-advisor-logo.png';
import openTableIcon from '../../assets/opentable-logo.png';

const NetworkIcon = ({ network }) => {
  switch (network) {
    case 'google':
      return <GoogleIcon />;
    case 'doordash':
      return <DoordashIcon />;
    case 'grubhub':
      return <GrubhubIcon />;
    case 'yelp':
      return <YelpIcon className="h-[24px] w-[24px] rounded-md" />;
    case 'tripadvisor':
      return <img src={tripAdvisorIcon} className="h-[24px] w-[24px] rounded-md" alt="tripadvisor" />;
    case 'ubereats':
      return <UberEatsIcon className="h-[24px] w-[24px]" />;
    case 'opentable':
      return <div className="bg-white p-[3px] rounded-md h-[24px] w-[24px]"><img src={openTableIcon} className="rounded-md" alt="opentable" /></div>
    default:
      return null;
  }
}

export default NetworkIcon;
