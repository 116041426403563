import Loader from "./Loader";

function DashboardLoader({ message }) {
  return (
    <div className="flex flex-col gap-6 items-center flex-grow justify-center">
      <Loader />

      {message && <p className="text-white text-lg font-semibold">{message}</p>}
    </div>
  );
}

export default DashboardLoader;
