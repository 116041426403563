import { useEffect, useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AuthContext } from "./AuthContext";
import { RestaurantContext } from "./RestaurantContext";

import ReviewSummary from "./Dashboard/ReviewSummary";
import RestaurantInfo from "./Dashboard/RestaurantInfo";
import DashboardLoader from "./DashboardLoader";
import ReviewSummaryLoader from "./Dashboard/ReviewSummaryLoader";
import RestaurantIndicator from "./RestaurantIndicator";

const UserDashboard = ({ restaurant, isProcessingResults, isGeneratingSummaries }) => {
  const { user, isProUser, setIsUpgradeToProOpen } = useContext(AuthContext);
  const { setRestaurant, selectedSnapshot, setSelectedSnapshot, setHasFetchedSnapshots, selectOptions, resetLoadedRestaurant } = useContext(RestaurantContext);

  const [currentAnalysis, setCurrentAnalysis] = useState('summary');
  const [overviewText, setOverviewText] = useState(restaurant?.analysis?.['summary']);

  const navigate = useNavigate();

  useEffect(() => {
    setOverviewText(restaurant?.analysis?.[currentAnalysis]);
  }, [currentAnalysis, restaurant]);

  const handleInstagramConnectClicked = () => {
    isProUser ? navigate('/find-instagram') : setIsUpgradeToProOpen(true);
  }

  const handleOpportunitiesClicked = () => {
    isProUser ? navigate('/opportunities') : setIsUpgradeToProOpen(true);
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 flex-grow pb-[16px] md:pb-0">
      <RestaurantInfo
        currentAnalysis={currentAnalysis}
        user={user}
        restaurant={restaurant}
        setRestaurant={setRestaurant}
        selectedSnapshot={selectedSnapshot}
        setSelectedSnapshot={setSelectedSnapshot}
        setHasFetchedSnapshots={setHasFetchedSnapshots}
        selectOptions={selectOptions}
        resetLoadedRestaurant={resetLoadedRestaurant}
        onAnalysisClicked={analysis => setCurrentAnalysis(analysis)}
        onOpportunitiesClicked={handleOpportunitiesClicked}
        isProUser={isProUser}
      />

      {isProcessingResults || isGeneratingSummaries
        ? <ReviewSummaryLoader />
        : <ReviewSummary
          currentAnalysis={currentAnalysis}
          overviewText={overviewText}
          onInstagramConnectClicked={handleInstagramConnectClicked}
        />}
    </div>
  )
}

function Dashboard() {
  const { user, isSubscriptionActive } = useContext(AuthContext);

  const {
    restaurant,
    isLoadingRestaurant,
    hasFetchedRestaurant,
    isGeneratingSummaries,
    isProcessingResults
  } = useContext(RestaurantContext);

  if (!restaurant && hasFetchedRestaurant) {
    return <Navigate to="/find-restaurant" replace />;
  }

  if (!isSubscriptionActive) {
    return <Navigate to="/profile" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Restaurant Report</title>
      </Helmet>

      {
        isLoadingRestaurant || !user
          ? <DashboardLoader />
          : <UserDashboard
            restaurant={restaurant}
            isProcessingResults={isProcessingResults}
            isGeneratingSummaries={isGeneratingSummaries}
          />
      }

      <RestaurantIndicator />
    </>
  );
}

export default Dashboard;