import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from 'react-hot-toast';

import apiService from "../services/apiService";

const CreateNewPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchForgotPasswordRequest = async () => {
      try {
        await apiService.fetchForgotPasswordRequest({ forgotPasswordRequestId: params.id });
      } catch (error) {
        console.log(error);
        navigate('/login');
      }
    }

    fetchForgotPasswordRequest();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await apiService.resetPassword({
        forgotPasswordRequestId: params.id,
        newPassword: password
      });

      toast.success('Password reset successful');
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='flex flex-col items-center justify-between flex-grow min-h-screen p-[16px]'>
      <Helmet>
        <title>Create New Password | Restaurant Report</title>
      </Helmet>

      <h1 className='text-[25px] font-semibold gap-1 flex items-center justify-center min-h-[150px]'>
        <span className='text-white'>Restaurant</span>
        <span className='text-r-blue'>Report</span>
      </h1>

      <div className='flex flex-col items-center justify-center flex-grow min-w-full'>
        <h2 className='text-center text-[32px] font-medium text-white mb-[24px]'>Create New Password</h2>

        <form onSubmit={handleSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
          <label className='flex flex-col gap-[16px] mb-[24px]'>
            <span className='text-white font-medium text-[17px]'>Password</span>

            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
            />
          </label>

          <label className='flex flex-col gap-[16px] mb-[24px]'>
            <span className='text-white font-medium text-[17px]'>Confirm password</span>

            <input
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
            />
          </label>

          <button type="submit" disabled={!password || !confirmPassword || password !== confirmPassword} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>Reset Password</button>
        </form>
      </div>
    </div>
  );
}

export default CreateNewPasswordForm;
