import { useState } from "react";

const FindRestaurantForm = ({ onSubmit }) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(name, address);
  }

  return (
    <form onSubmit={handleSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
      <label className='flex flex-col gap-[16px] mb-[24px]'>
        <span className='text-white font-medium text-[17px]'>Name of your restaurant</span>

        <input
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Restaurant Name"
          className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
        />
      </label>

      <label className='flex flex-col gap-[16px] mb-[16px]'>
        <span className='text-white font-medium text-[17px]'>Address of your restaurant</span>

        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Restaurant Address"
          className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
        />
      </label>

      <button type="submit" disabled={!name || !address} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>Search</button>
    </form>
  )
}

export default FindRestaurantForm;
