import { useContext } from "react"
import { RestaurantContext } from "./RestaurantContext"

const RestaurantIndicator = () => {
  const { selectedSnapshot } = useContext(RestaurantContext);

  return (
    selectedSnapshot && selectedSnapshot.snapshotDate && (
      <div className="fixed bottom-4 right-4 bg-r-gray-dark text-white font-bold text-sm py-1 px-2 rounded-md shadow-sm shadow-r-blue text-center">
        <p>Now spying on: <span className="text-r-blue">{selectedSnapshot?.restaurant?.google?.name}</span></p>
        <p>Data last updated on: <span className="text-r-blue">{selectedSnapshot?.snapshotDate}</span></p>
      </div>
    )
  )
}

export default RestaurantIndicator;
