import { useState, useContext, useEffect } from 'react';

import { RestaurantContext } from '../RestaurantContext';

import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { ReactComponent as CaretIcon } from '../../assets/caret-icon.svg';

import searchTopics from '../../data/searchTopics.json';
import searchNetworks from '../../data/searchNetworks.json';

const ResultSearchForm = ({ onSearch, searchTerm, preselectedNetworks, preselectedTopics }) => {
  const [text, setText] = useState(searchTerm || '');

  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);

  const { availableNetworks } = useContext(RestaurantContext);

  const [isPlatformOpen, setIsPlatformOpen] = useState(false);
  const [isTopicOpen, setIsTopicOpen] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();

    setIsPlatformOpen(false);
    setIsTopicOpen(false);
    onSearch({ text, networks: selectedNetworks, topics: selectedTopics });
  }

  const handleNetworkChecked = (network) => {
    if (selectedNetworks.includes(network)) {
      setSelectedNetworks(selectedNetworks.filter((selectedNetwork) => selectedNetwork !== network));
    } else {
      setSelectedNetworks([...selectedNetworks, network]);
    }
  }

  const handleTopicChecked = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter((selectedTopic) => selectedTopic !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  }

  useEffect(() => {
    setSelectedNetworks(preselectedNetworks);
  }, [preselectedNetworks]);

  useEffect(() => {
    setSelectedTopics(preselectedTopics);
  }, [preselectedTopics]);

  let networkSelectionText = 'All';

  if (selectedNetworks.length === 1) {
    networkSelectionText = searchNetworks.find(network => network.value === selectedNetworks[0]).name;
  }

  if (selectedNetworks.length > 1) {
    networkSelectionText = searchNetworks.find(network => network.value === selectedNetworks[0]).name + ' + ' + (selectedNetworks.length - 1);
  }

  if (selectedNetworks.length === availableNetworks.length) {
    networkSelectionText = 'All';
  }

  let topicSelectionText = 'All';

  if (selectedTopics.length === 1) {
    topicSelectionText = searchTopics.find(topic => topic.value === selectedTopics[0]).name;
  }

  if (selectedTopics.length > 1) {
    topicSelectionText = searchTopics.find(topic => topic.value === selectedTopics[0]).name + ' + ' + (selectedTopics.length - 1);
  }

  if (selectedTopics.length === searchTopics.length) {
    topicSelectionText = 'All';
  }

  return (
    <form className="flex flex-col md:flex-row items-center gap-[16px] w-full" onSubmit={handleSearch}>
      <label className="flex flex-col gap-[8px] w-full md:w-[320px] flex-grow">
        <span className="text-white font-medium text-[16px] leading-[24px]">Keyword</span>

        <div className="relative w-full">
          <SearchIcon className="absolute top-[50%] left-[16px] transform -translate-y-1/2 stroke-[#556277]" />

          <input
            type="text"
            className="h-[56px] p-[16px] pl-[52px] bg-[#1D2839] rounded-md text-[16px] text-white border-none w-full"
            placeholder="Any keyword"
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </div>
      </label>

      <div className='grid grid-cols-2 gap-[16px] w-full md:flex md:w-auto'>
        <div className="flex flex-col gap-[8px] w-full md:w-[160px]">
          <span className="text-white font-medium text-[16px] leading-[24px]">Platforms</span>

          <div className="relative">
            <div className='w-full select-none' onClick={e => setIsPlatformOpen(!isPlatformOpen)}>
              <div className='h-[56px] p-[16px] bg-[#1D2839] rounded-md border-none w-full cursor-pointer'>
                <p className='text-[16px] font-medium text-white'>{networkSelectionText}</p>
              </div>

              <CaretIcon className={`${isPlatformOpen ? 'rotate-180 stroke-r-blue' : ''} absolute top-[50%] right-[30px] stroke-[#728096] -translate-y-1/2 pointer-events-none`} />
            </div>

            <div className={`${isPlatformOpen ? '' : 'hidden'} absolute top-[105%] w-full bg-[#1D2839]`}>
              <div className="flex flex-col gap-[13px] p-[16px] rounded-md text-[16px] text-white border-none w-full rounded-b-md">
                {searchNetworks.filter(network => availableNetworks.includes(network.value)).map((network) => (
                  <label key={`network-${network.value}`} className="flex items-center text-white font-semibold text-[14px] flex-grow">
                    <input
                      type="checkbox"
                      checked={selectedNetworks.includes(network.value)}
                      onChange={() => handleNetworkChecked(network.value)}
                      className='mr-[8px] bg-transparent border border-[#B9BECF] checked:bg-r-blue focus:bg-r-blue checked:focus:bg-r-blue focus:ring-0 focus:shadow-none focus-visible:shadow-none rounded-sm hover:bg-r-blue checked:hover:bg-r-blue focus:outline-none hover:ring-0 focus:border-none'
                    />

                    {network.name}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[8px] w-full md:w-[160px]">
          <span className="text-white font-medium text-[16px] leading-[24px]">Topics</span>

          <div className="relative">
            <div className='w-full' onClick={e => setIsTopicOpen(!isTopicOpen)}>
              <div className='h-[56px] p-[16px] bg-[#1D2839] rounded-md border-none w-full cursor-pointer'>
                <p className='text-[16px] font-medium text-white'>{topicSelectionText}</p>
              </div>

              <CaretIcon className={`${isTopicOpen ? 'rotate-180 stroke-r-blue' : ''} absolute top-[50%] right-[30px] stroke-[#728096] -translate-y-1/2 pointer-events-none`} />
            </div>

            <div className={`${isTopicOpen ? '' : 'hidden'} absolute top-[105%] w-full bg-[#1D2839]`}>
              <div className="flex flex-col gap-[13px] p-[16px] rounded-md text-[16px] text-white border-none w-full rounded-b-md">
                {searchTopics.map((topic) => (
                  <label key={`network-${topic.value}`} className="flex items-center text-white font-semibold text-[14px]">
                    <input
                      type="checkbox"
                      checked={selectedTopics.includes(topic.value)}
                      onChange={() => handleTopicChecked(topic.value)}
                      className='mr-[8px] bg-transparent border border-[#B9BECF] checked:bg-r-blue focus:bg-r-blue checked:focus:bg-r-blue focus:ring-0 focus:shadow-none focus-visible:shadow-none rounded-sm hover:bg-r-blue checked:hover:bg-r-blue focus:outline-none hover:ring-0 focus:border-none'
                    />
                    {topic.name}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="h-[56px] bg-[#18A6C5] self-end w-full md:w-[160px] rounded-md text-white font-semibold text-[15px]"
        type="submit"
      >
        Search
      </button>
    </form>
  )
}

export default ResultSearchForm;
