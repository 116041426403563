import { useState, useEffect } from "react";

import apiService from "../services/apiService";

const useFetchReviewCountsbyTopic = (restaurant, availableNetworks, isProcessingResults) => {
  const [topicReviews, setTopicReviews] = useState(null);
  const [startedFetchingReviewsByTopic, setStartedFetchingReviewsByTopic] = useState(false);

  useEffect(() => {
    const fetchReviewCountsByTopic = async () => {
      try {
        const response = await apiService.fetchReviewCountsByTopic({ restaurantId: restaurant._id });

        const topicReviews = {};

        Object.keys(response.data[0]).forEach((topicKey) => {
          const topicData = response.data[0][topicKey];

          topicData.forEach((topic) => {
            const { _id: { network, rating }, count } = topic;

            topicReviews[topicKey] = topicReviews[topicKey] || {};
            topicReviews[topicKey][network] = topicReviews[topicKey][network] || {};
            topicReviews[topicKey][network]['ratingMap'] = topicReviews[topicKey][network]['ratingMap'] || {};
            topicReviews[topicKey][network]['ratingMap'][rating] = topicReviews[topicKey][network]['ratingMap'][rating] || 0;
            topicReviews[topicKey][network]['ratingMap'][rating] += count;
            topicReviews[topicKey][network].total = topicReviews[topicKey][network].total || 0;
            topicReviews[topicKey][network].total += count;
          });
        });

        Object.keys(topicReviews).forEach((topicKey) => {
          const topicData = topicReviews[topicKey];

          Object.keys(topicData).forEach((networkKey) => {
            const networkData = topicData[networkKey];

            let totalReviews = 0;
            let weightedSum = 0;

            for (const rating in networkData['ratingMap']) {
              if (rating === 'total') {
                continue;
              }

              const count = networkData['ratingMap'][rating];
              totalReviews += count;
              weightedSum += Number(rating) * count;
              topicData['ratingMap'] = topicData['ratingMap'] || {};
              topicData['ratingMap'][rating] = topicData['ratingMap'][rating] || 0;
              topicData['ratingMap'][rating] += count;
              networkData.average = totalReviews ? Number(weightedSum / totalReviews).toFixed(1) : 0;
              networkData.total = totalReviews;
            }
          });

          topicData['ratingMap'] = Object.keys(topicData['ratingMap']).reduce((acc, rating) => {
            if (rating === 'total') {
              return acc;
            }

            acc.push({
              rating: Number(rating),
              count: topicData['ratingMap'][rating]
            });

            return acc;
          }, []);

          for (let i = 1; i <= 5; i++) {
            const rating = topicData['ratingMap'].find(rating => rating.rating === i);

            if (!rating) {
              topicData['ratingMap'].push({
                rating: i,
                count: 0
              });
            }
          }
        });

        topicReviews['summary'] = {};

        availableNetworks.filter(network => network !== 'instagram').forEach(network => {
          const totalReviews = restaurant?.[network]?.reviewCount?.native?.total || 0;
          const averageRating = restaurant?.[network]?.globalRating || 0;

          topicReviews['summary'][network] = {
            total: totalReviews,
            average: averageRating
          };
        });

        setTopicReviews(topicReviews);
      } catch (error) {
        console.error("Error fetching review counts by topic:", error);
      }
    };


    if (restaurant && !startedFetchingReviewsByTopic && !isProcessingResults) {
      setStartedFetchingReviewsByTopic(true);
      fetchReviewCountsByTopic();
    }
  }, [restaurant, isProcessingResults, setStartedFetchingReviewsByTopic]);

  return { startedFetchingReviewsByTopic, topicReviews, setStartedFetchingReviewsByTopic };
};

export default useFetchReviewCountsbyTopic;