import { ReactComponent as InsightsIcon } from '../../assets/insights-icon.svg';
import { ReactComponent as IdeaIcon } from '../../assets/lamp-icon.svg';

const InitialQuestions = ({ onMessageClicked }) => {
  const questions = [
    'What do guests think about our bartenders?',
    'What cocktails are people talking about the most?',
    'What are guests saying about our portions?',
    'What can we do to increase bar sales?'
  ];

  return (
    <div className="flex flex-col flex-grow items-center justify-center p-[16px] md:p-0">
      <InsightsIcon className="w-[72px] h-[72px] fill-r-blue mb-[52px]" />

      <div className="flex flex-col gap-[16px]">
        {questions.map((question, index) => (
          <div
            onClick={() => onMessageClicked(question)}
            className="border-2 border-[#1D2839] rounded-lg h-[72px] flex items-center w-full md:w-[466px] p-[27px] gap-[8px] cursor-pointer"
            key={`question-${index}`}
          >
            <IdeaIcon className="fill-r-blue stroke-r-blue" />

            <p className="font-medium text-[15px] text-white text-center">{question}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InitialQuestions;
