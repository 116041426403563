import { useContext, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";

import { AuthContext } from './AuthContext';
import { RestaurantContext } from './RestaurantContext';

import apiService from '../services/apiService';

import DashboardLoader from "./DashboardLoader";
import Modal from './Modal';
import RelatedReviewsList from './RelatedReviewsList';
import OpportunityDetails from './Opportunities/OpportunityDetails';

import { ReactComponent as LightbulbIcon } from '../assets/lightbulb-icon.svg';
import RestaurantIndicator from './RestaurantIndicator';

const UserOpportunities = ({ opportunities }) => {
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [selectedReviews, setSelectedReviews] = useState([]);

  const [isRelatedReviewsModalOpen, setIsRelatedReviewsModalOpen] = useState(false);
  const [isOpportunityDetailsModalOpen, setIsOpportunityDetailsModalOpen] = useState(false);

  const handleShowMeHowClicked = (opportunity) => {
    setSelectedOpportunity(opportunity);
    setIsOpportunityDetailsModalOpen(true);
  }

  const handleViewReviewsClicked = (reviews) => {
    setSelectedReviews(reviews);
    setIsRelatedReviewsModalOpen(true);
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow relative py-[50px]">
      <div className="flex flex-col gap-[16px] flex-grow items-center justify-center p-[16px] md:p-0">
        <LightbulbIcon className="stroke-r-blue h-[64px] w-[64px] mb-[30px]" />

        {opportunities.map(opportunity => (
          <div key={opportunity._id} className='border-2 border-[#1D2839] rounded-lg flex items-center w-full md:w-[900px] p-[27px] gap-4'>
            <LightbulbIcon className='stroke-r-blue h-[32px] w-[32px] min-w-[32px]' />

            <div className='flex flex-col gap-2'>
              <p className='text-white font-semibold'>{opportunity.title}</p>
              <p className='text-white text-sm font-medium mb-2'>{opportunity.impactReason}</p>

              <div className='flex items-center gap-2'>
                <button
                  onClick={() => handleShowMeHowClicked(opportunity)}
                  className='bg-r-blue text-sm font-medium text-white rounded-md px-2 py-1'
                >
                  Show me how
                </button>

                <button
                  onClick={() => handleViewReviewsClicked(opportunity.reviews)}
                  className='bg-r-blue text-sm font-medium text-white rounded-md px-2 py-1'
                >
                  View related reviews
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal isOpen={isOpportunityDetailsModalOpen}>
        <OpportunityDetails
          opportunity={selectedOpportunity}
          onCloseClicked={() => setIsOpportunityDetailsModalOpen(false)}
        />
      </Modal>

      <Modal isOpen={isRelatedReviewsModalOpen}>
        <RelatedReviewsList
          reviews={selectedReviews}
          onCloseClicked={() => setIsRelatedReviewsModalOpen(false)}
        />
      </Modal>
    </div>
  )
};

const Opportunities = () => {
  const { user, isSubscriptionActive, isProUser } = useContext(AuthContext);
  const { restaurant, isLoadingRestaurant, hasFetchedRestaurant, opportunities, setOpportunities } = useContext(RestaurantContext);

  const [isLoadingOpportunities, setIsLoadingOpportunities] = useState(false);
  const [hasFetchedOpportunities, setHasFetchedOpportunities] = useState(false);

  useEffect(() => {
    const fetchOpportunities = async () => {
      setIsLoadingOpportunities(true);

      try {
        const response = await apiService.fetchRestaurantOpportunities({ restaurantId: restaurant._id });
        setOpportunities(response.data);
        setHasFetchedOpportunities(true);
      } catch (error) {
        console.log(error);
      }

      setIsLoadingOpportunities(false);
    };

    if (!opportunities && !isLoadingOpportunities && !hasFetchedOpportunities) {
      fetchOpportunities();
    }
  }, [restaurant, isLoadingOpportunities, hasFetchedOpportunities]);

  if (!restaurant && hasFetchedRestaurant) {
    return <Navigate to="/find-restaurant" replace />;
  }

  if (!isSubscriptionActive) {
    return <Navigate to="/profile" replace />;
  }

  if (user && !isProUser) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Opportunities | Restaurant Report</title>
      </Helmet>

      {isLoadingRestaurant || !user || isLoadingOpportunities || !opportunities
        ? <DashboardLoader />
        : <UserOpportunities
          opportunities={opportunities}
        />
      }

      <RestaurantIndicator />
    </>
  )
}

export default Opportunities;
