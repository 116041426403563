const SEARCH_NETWORKS = [
  {
    "name": "Google",
    "value": "google"
  },
  {
    "name": "Yelp",
    "value": "yelp"
  },
  {
    "name": "OpenTable",
    "value": "opentable"
  },
  {
    "name": "GrubHub",
    "value": "grubhub"
  },
  {
    "name": "DoorDash",
    "value": "doordash"
  },
  {
    "name": "UberEats",
    "value": "ubereats"
  },
  {
    "name": "TripAdvisor",
    "value": "tripadvisor"
  },
  {
    "name": "Instagram",
    "value": "instagram"
  }
];

const CONSTANTS = {
  ENV: 'PROD',

  DEV: {
    API_URL: 'http://localhost:3001/api',
  },

  PROD: {
    API_URL: 'https://api.restaurantreport.ai/api'
  }
};

export const API_URL = CONSTANTS[CONSTANTS.ENV].API_URL;
export { SEARCH_NETWORKS };

