import { Link, NavLink, useNavigate } from "react-router-dom";

import { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';
import { ReactComponent as SearchIcon } from '../assets/search-icon.svg';
import { ReactComponent as InsightsIcon } from '../assets/insights-icon.svg';
import { ReactComponent as InfoIcon } from '../assets/info-icon.svg';
import { ReactComponent as UserIcon } from '../assets/user-icon.svg';
import { ReactComponent as LightbulbIcon } from '../assets/lightbulb-icon.svg';

function Navbar({ onPrivacyPolicyClicked, onTermsOfServiceClicked }) {
  const [isOpen, setIsOpen] = useState(false);

  const { isProUser, setIsUpgradeToProOpen } = useContext(AuthContext);
  const navigate = useNavigate();

  const handlePremiumLinkClicked = (e, path) => {
    e.preventDefault();

    if (isProUser) {
      navigate(path);
    } else {
      setIsUpgradeToProOpen(true);
    }
  }

  return (
    <nav className='hidden md:flex flex-col justify-between w-[104px] items-center bg-r-gray-dark pt-[24px] pb-[32px] min-h-full fixed top-0 bottom-0 left-0 z-10'>
      <Link to="/dashboard" className='flex flex-col items-center font-semibold text-[16px] tracking-tight leading-none'>
        <span className='text-white'>Restaurant</span>
        <span className='text-r-blue'>Report</span>
      </Link>

      <div className="flex flex-col gap-[30px] items-center justify-center">
        <NavLink to="/dashboard" className={({ isActive }) => `${isActive ? 'stroke-[#18A6C5]' : 'stroke-[#556277] '} hover:stroke-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}>
          <HomeIcon />
        </NavLink>

        <NavLink
          to="/search"
          className={({ isActive }) => `${isActive ? 'stroke-[#18A6C5]' : 'stroke-[#556277] '} hover:stroke-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}
        >
          <SearchIcon />
        </NavLink>

        <NavLink
          to="/insights"
          onClick={e => handlePremiumLinkClicked(e, '/insights')}
          className={({ isActive }) => `${isActive ? 'fill-[#18A6C5]' : 'fill-[#556277] '} hover:fill-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}
        >
          <InsightsIcon />
        </NavLink>

        <NavLink
          to="/opportunities"
          onClick={e => handlePremiumLinkClicked(e, '/opportunities')}
          className={({ isActive }) => `${isActive ? 'stroke-[#18A6C5]' : 'stroke-[#556277]'} hover:stroke-[#18A6C5] border border-gray-900 p-2 px-4 text-sm`}
        >
          <LightbulbIcon />
        </NavLink>
      </div>

      <div className="flex flex-col gap-[40px] items-center justify-center">
        <div className="relative">
          <InfoIcon onClick={e => setIsOpen(!isOpen)} className={`${isOpen ? 'stroke-[#18A6C5]' : 'stroke-[#556277]'} cursor-pointer hover:stroke-[#18A6C5]`} />

          <div className={`${isOpen ? 'flex' : 'hidden'} flex-col absolute left-20 -top-full bg-[#202731] rounded-md gap-[16px] w-[152px] items-start pt-[12px] pb-[12px] pl-[12px] text-white text-left text-[14px] font-medium`}>
            <button onClick={onPrivacyPolicyClicked} className="hover:text-r-blue">Privacy Policy</button>
            <button onClick={onTermsOfServiceClicked} className="hover:text-r-blue">Terms & Conditions</button>
          </div>
        </div>

        <Link to="/profile" className='p-[18px] bg-r-gray-medium rounded-md'>
          <UserIcon />
        </Link>
      </div>
    </nav>
  )
}

export default Navbar;
